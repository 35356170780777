import axios from "axios";
import {
  loginFail,
  loginRequest,
  loginSuccess,
  clearError,
  registerFail,
  registerRequest,
  registerSuccess,
  loadUserRequest,
  loadUserSuccess,
  loadUserFail,
  logoutSuccess,
  logoutFail,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFail,
  updatePasswordRequest,
  updatePasswordSuccess,
  updatePasswordFail,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFail,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFail,
} from "../slices/authSlice";

// Set Axios Defaults
axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://igold.teammatrixmantra.com"; // Update with your backend URL

// Login Action
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    const { data } = await axios.post(`/jewellery/login`, { email, password });
    dispatch(loginSuccess(data));
  } catch (error) {
    dispatch(loginFail(error.response?.data?.message || "Login failed"));
  }
};

// Clear Auth Error
export const clearAuthError = () => (dispatch) => {
  dispatch(clearError());
};

// Register Action
export const register = (userData) => async (dispatch) => {
  try {
    dispatch(registerRequest());
    const config = { headers: { "Content-type": "multipart/form-data" } };
    const { data } = await axios.post(`/jewellery/register`, userData, config);
    dispatch(registerSuccess(data));
  } catch (error) {
    dispatch(
      registerFail(error.response?.data?.message || "Registration failed")
    );
  }
};

// Load User Profile
export const loadUser = () => async (dispatch) => {
  try {
    dispatch(loadUserRequest());
    const { data } = await axios.get(`/jewellery/myprofile`);
    dispatch(loadUserSuccess(data));
  } catch (error) {
    dispatch(
      loadUserFail(error.response?.data?.message || "Failed to load user")
    );
  }
};

// Logout Action
export const logout = () => async (dispatch) => {
  try {
    await axios.get(`/jewellery/logout`);
    dispatch(logoutSuccess());
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; // Clear cookie manually if needed
  } catch (error) {
    dispatch(logoutFail(error.response?.data?.message || "Logout failed"));
  }
};

// Update User Profile
export const updateProfile = (userData) => async (dispatch) => {
  try {
    dispatch(updateProfileRequest());
    const config = { headers: { "Content-type": "multipart/form-data" } };
    const { data } = await axios.put(`/jewellery/update`, userData, config);
    dispatch(updateProfileSuccess(data));
  } catch (error) {
    dispatch(
      updateProfileFail(
        error.response?.data?.message || "Failed to update profile"
      )
    );
  }
};

// Update Password
export const updatePassword = (formData) => async (dispatch) => {
  try {
    dispatch(updatePasswordRequest());
    const config = { headers: { "Content-type": "application/json" } };
    await axios.put(`/jewellery/password/change`, formData, config);
    dispatch(updatePasswordSuccess());
  } catch (error) {
    dispatch(
      updatePasswordFail(
        error.response?.data?.message || "Failed to update password"
      )
    );
  }
};

// Forgot Password
export const forgotPassword = (formData) => async (dispatch) => {
  try {
    dispatch(forgotPasswordRequest());
    const config = { headers: { "Content-type": "application/json" } };
    const { data } = await axios.post(
      `/jewellery/password/forgot`,
      formData,
      config
    );
    dispatch(forgotPasswordSuccess(data));
  } catch (error) {
    dispatch(
      forgotPasswordFail(
        error.response?.data?.message || "Failed to request password reset"
      )
    );
  }
};

// Reset Password
export const resetPassword = (formData, token) => async (dispatch) => {
  try {
    dispatch(resetPasswordRequest());
    const config = { headers: { "Content-type": "application/json" } };
    const { data } = await axios.post(
      `/jewellery/password/reset/${token}`,
      formData,
      config
    );
    dispatch(resetPasswordSuccess(data));
  } catch (error) {
    dispatch(
      resetPasswordFail(
        error.response?.data?.message || "Failed to reset password"
      )
    );
  }
};
