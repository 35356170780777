import axios from "axios";
import {
  productsRequest,
  productsFail,
  productsSuccess,
  adminProductsRequest,
  adminProductsSuccess,
  adminProductsFail,
} from "../slices/productsSlice";

import {
  productFail,
  productSuccess,
  productRequest,
  newProductRequest,
  newProductSuccess,
  newProductFail,
  deleteProductRequest,
  deleteProductSuccess,
  deleteProductFail,
  updateProductRequest,
  updateProductSuccess,
  updateProductFail,
} from "../slices/productSlice";

import {
  relatedProductsRequest,
  relatedProductsSuccess,
  relatedProductsFail,
} from "../slices/relatedProductsSlice";

// Get all products
export const getProducts = async (dispatch) => {
  try {
    dispatch(productsRequest());
    const { data } = await axios.get(
      "https://igold.teammatrixmantra.com/jewellery/products",
      { withCredentials: true }
    );
    dispatch(productsSuccess(data));
  } catch (error) {
    console.error("Error fetching products:", error); // Log the error for debugging
    const errorMessage =
      error.response?.data?.message || "Failed to fetch products";
    dispatch(productsFail(errorMessage));
  }
};

// Get a single product by ID
export const getProduct = (id) => async (dispatch) => {
  try {
    dispatch(productRequest());
    const { data } = await axios.get(
      `https://igold.teammatrixmantra.com/jewellery/product/${id}`,
      { withCredentials: true }
    );
    console.log("Product Data from API:", data); // Log data for debugging
    dispatch(productSuccess(data));
    return { product: data };
  } catch (error) {
    console.error("Error fetching product:", error); // Log full error details
    const errorMessage =
      error.response?.data?.message || "Failed to fetch product";
    dispatch(productFail(errorMessage));
  }
};

// Get related products based on category and exclude current product
export const getRelatedProducts =
  (category, excludeProductId) => async (dispatch) => {
    try {
      dispatch(relatedProductsRequest());
      const { data } = await axios.get(
        `https://igold.teammatrixmantra.com/jewellery/products?category=${category}&exclude=${excludeProductId}`,
        { withCredentials: true }
      );
      dispatch(relatedProductsSuccess(data)); // Dispatch success action with related products
      return { success: true, data }; // Return data for further use if needed
    } catch (error) {
      console.error("Error fetching related products:", error); // Log full error details
      const errorMessage =
        error.response?.data?.message || "Failed to fetch related products";
      dispatch(relatedProductsFail(errorMessage)); // Dispatch failure action
      return { success: false, error: error.message }; // Return failure status and error message
    }
  };

// Get admin products
// Fetch admin products
export const getAdminProducts = () => async (dispatch) => {
  try {
    dispatch(adminProductsRequest());
    const { data } = await axios.get(
      `https://igold.teammatrixmantra.com/jewellery/admin/products`,
      { withCredentials: true }
    );
    dispatch(adminProductsSuccess(data));
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to fetch admin products";
    dispatch(adminProductsFail(errorMessage));
  }
};

// Create a new product (admin action)
export const createNewProduct = (productData) => async (dispatch) => {
  try {
    dispatch(newProductRequest());
    const { data } = await axios.post(
      `https://igold.teammatrixmantra.com/jewellery/admin/product/new`,
      productData,
      { withCredentials: true }
    );
    dispatch(newProductSuccess(data));
  } catch (error) {
    console.error("Error creating new product:", error); // Log the error for debugging
    const errorMessage =
      error.response?.data?.message || "Failed to create product";
    dispatch(newProductFail(errorMessage));
  }
};

// Delete a product (admin action)
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch(deleteProductRequest());
    await axios.delete(
      `https://igold.teammatrixmantra.com/jewellery/admin/product/${id}`,
      { withCredentials: true }
    );
    dispatch(deleteProductSuccess());
  } catch (error) {
    console.error("Error deleting product:", error); // Log the error for debugging
    const errorMessage =
      error.response?.data?.message || "Failed to delete product";
    dispatch(deleteProductFail(errorMessage));
  }
};

// Update a product (admin action)
export const updateProduct = (id, productData) => async (dispatch) => {
  try {
    dispatch(updateProductRequest());
    const { data } = await axios.put(
      `https://igold.teammatrixmantra.com/jewellery/admin/product/${id}`,
      productData,
      { withCredentials: true }
    );
    dispatch(updateProductSuccess(data));
  } catch (error) {
    console.error("Error updating product:", error); // Log the error for debugging
    const errorMessage =
      error.response?.data?.message || "Failed to update product";
    dispatch(updateProductFail(errorMessage));
  }
};
