import { configureStore } from "@reduxjs/toolkit";
import productsReducer from "./slices/productsSlice";
import productReducer from "./slices/productSlice";
import relatedProductReducer from "./slices/relatedProductsSlice";
import authReducer from "./slices/authSlice";
import cartReducer from "./slices/cartSlice";
import orderReducer from "./slices/orderSlice";

const store = configureStore({
  reducer: {
    productsState: productsReducer,
    productState: productReducer,
    relatedProductState: relatedProductReducer,
    authState: authReducer,
    cartState: cartReducer,
    orderState: orderReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }), // Adjust middleware for custom non-serializable cases if needed
});

export default store;
