import {
  AllOrderRequest,
  AllorderFail,
  AllorderSuccess,
  createOrderFail,
  createOrderRequest,
  createOrderSuccess,
  deleteOrder,
  deleteOrderFail,
  deleteOrderSuccess,
  userOrderRequest,
  userOrderSuccess,
  userOrerFail,
} from "../slices/orderSlice";
import axios from "axios";

export const createOrder = (order) => async (dispatch) => {
  try {
    dispatch(createOrderRequest);
    const { data } = await axios.post(
      `https://igold.teammatrixmantra.com/confirm/Order`,
      order
    );
    dispatch(createOrderSuccess(data));
  } catch (error) {
    dispatch(createOrderFail(error.response.data.message));
  }
};

export const getAllOrders = async (dispatch) => {
  try {
    dispatch(AllOrderRequest());
    const { data } = await axios.get(
      `https://igold.teammatrixmantra.com/confirm/allOrder`
    );
    dispatch(AllorderSuccess(data));
  } catch (error) {
    dispatch(AllorderFail(error.response.data.message));
  }
};

export const DeleteOrders = (id) => async (dispatch) => {
  try {
    dispatch(deleteOrder());
    await axios.delete(
      `https://igold.teammatrixmantra.com/confirm/delete/${id}`
    );
    dispatch(deleteOrderSuccess());
  } catch (error) {
    //handle error
    dispatch(deleteOrderFail(error.response.data.message));
  }
};

export const getUserOrder = (id) => async (dispatch) => {
  try {
    dispatch(userOrderRequest());
    const data = await axios.get(
      `https://igold.teammatrixmantra.com/confirm/user/order/${id}`
    );
    dispatch(userOrderSuccess(data));
  } catch (error) {
    dispatch(userOrerFail(error.response.data.message));
  }
};
